<template>
  <div class="container">
    <div class="white-content">
      <div class="header1">
        <router-link to="/" style="text-decoration: none; color: black;">
          <h2>&lt;智芯X.PAD</h2>
        </router-link>
      </div>
      <div class="function-container">
        <div class="function-item TJ" :class="{ selected: isTJSelected }" @click="selectFunction('TJ')">
          <i class="fas"></i>
          <p>添加页面</p>
        </div>
        <div class="function-item LJ" id="connectButton" @click="selectFunction('LJ')">
          <i class="fas"></i>
          <p>设备连接</p>
        </div>
        <select-response-modal v-if="showResponseModal" :title="'搜索结果'" :responseData="responseData"
          @confirm="handleConfirm" @close="handleClose" />
        <div class="function-item UP" id="checkConnectionButton" @click="selectFunction('UP')">
          <i class="fas"></i>
          <p>{{ status }}</p>
        </div>
        <div class="showProgress" v-if="showProgress">
          <i class="fas"></i>
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progress.toFixed(2) + '%' }"></div>
          </div>
          <p>{{ progress.toFixed(2) }}%</p>
        </div>
      </div>
      <div class="nav-items1">
        <p class="icon-line">
          <span style="color: rgb(0, 245, 0)" id="batteryPercentage">{{ batteryPercentage }}%</span>
          <i class="fas fa-battery-half" style="color: rgb(6, 255, 6);"></i>
          <i>
            <svg t="1720085179326" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
              p-id="2272" width="16" height="16">
              <path
                d="M932.352 310.272L434.688 0v402.944L145.92 222.72 91.648 307.2l324.096 204.8-324.096 204.8 54.272 87.04 288.768-180.224V1024l497.664-310.784L609.28 512zM537.088 184.32l201.728 125.952-201.728 125.952zM614.4 636.928l122.88 76.8-200.192 125.952v-251.904z"
                fill="#333333" p-id="2273">
              </path>
            </svg>
          </i>
        </p>
      </div>
    </div>
    <!--测试添加页面-->
    <div class="gray-bg">
      <swiper ref="mySwiper" :slidesPerView="auto" :spaceBetween="10" :centeredSlides="true"
        :pagination="paginationOptions" :modules="modules" class="mySwiper">
        <!-- 根据当前要显示的幻灯片数量动态渲染幻灯片 -->
        <swiper-slide v-for="(image, index) in imagesToShow" :key="index">
          <p class="text-img">{{ image.text }} </p>
          <img class="img-div" :src="image.src" alt="" @click="loadContent(image.id)" > 
          <button v-if="index !== 0" class="delete-button" @click="handleDelete(image.id)">删除页面</button>

        </swiper-slide>
      </swiper>

    </div>

    <div v-if="isSearching" class="modal">
      <div class="modal-content">OO <div v-for="(image, index) in displayedImages" :key="index"
          :class="['imageDiv', 'image' + (index + 1)]">
          <img class="img-div" :src="image.src" :alt="'Image ' + (index + 1)" @click="loadContent(image.id)">
        </div>
        <p>正在搜索，请稍候...(如场长时间搜索无响应，请点击关闭后重试！)</p>
        <button @click="closeModal">关闭</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ElMessageBox } from 'element-plus';
import SelectResponseModal from './SelectResponseModal.vue';
import { transfer } from "@/Modules/ymodem";
import { delay_ms } from "@/utils/index";
import { serial as usb_serial } from "@/Modules/google_serial";
import Cookies from 'js-cookie';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, A11y, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  components: {
    Swiper,
    SwiperSlide,
    SelectResponseModal,
  },

  data() {
    return {
      isTJSelected: false,
      showProgress: false,
      batteryPercentage: 50,
      images: [
        { src: require('@/assets/9.png'), id: 1, text: '页面一' },
        { src: require('@/assets/9.png'), id: 2, text: '页面二' },
        { src: require('@/assets/9.png'), id: 3, text: '页面三' },
      ],
      displayCount: 1,  // 初始化显示的图像数量 这个控制返回的数量
      currentImageIndex: 0, //显示几张
      clickedImageIndex: null, // 点击的图像索引
      displayedImages: [],
      searchInterval: null, // 用于保存定时器ID
      imagesMap: [],
      firmwareUpdateStatus: '',
      serialData: {},
      fileData: null,
      serialPort: null,
      writer: null,
      reader: null,
      status: '固件更新',
      progress: 0,
      showResponseModal: false,
      responseData: [],
      selectedItem: null,
      isSearching: false,
      isReading: false,
      hostApi: navigator.serial ? navigator.serial : usb_serial,
      modules: [Navigation, Pagination, A11y, Mousewheel], // Swiper 模块
      paginationOptions: {
        clickable: true,
      },
      state: [0, 0],
    };
  },

  computed: {
    imagesToShow() {
      // 确保第一页始终显示
      const pagesToShow = [this.images[0]];
      // 根据状态决定其他页面是否显示
      if (this.state[0] === 1) {
        pagesToShow.push(this.images[1]); // 第2页
      }
      if (this.state[1] === 1) {
        pagesToShow.push(this.images[2]); // 第3页
      }
      return pagesToShow;
    },
    ...mapGetters(['getSerialData', 'getDeviceInfo', 'getWriter', 'getReader', 'getFileData', 'getSerialPort']),

  },
  mounted() {
    this.setConnectButtonStyle();

    // this.fetchDisplayCount();
  },
//   watch: {
//   imagesToShow() {
//     this.$nextTick(() => {
//       this.updateCanvas(); // 当 imagesToShow 更新时重新绘制
//     });
//   }
// },
  methods: {
    ...mapActions(['connectSerial', 'sendSerialData', 'disconnectSerial']),
    setConnectButtonStyle() {
      if (this.getWriter) {
        this.writer = this.getWriter;
      }
      if (this.getReader) {
        this.reader = this.getReader;
      }
      if (this.getSerialPort) {
        this.serialPort = this.getSerialPort;
      }
      if (this.getDeviceInfo) {
        const DeviceInfo = this.getDeviceInfo;
        this.batteryPercentage = DeviceInfo.battery_level;
        const connectButton = document.getElementById('connectButton');
        connectButton.style.backgroundColor = 'rgb(0, 245, 0)';
        //判断显示多少页面
        this.getPage(DeviceInfo.id);
      }
    },
    
    getPage(id) {
      const getblepagestate = {
        "id": id,
        "method": "get_ble_page_state",
      }
      const res = this.sendSerialData(getblepagestate);
      res.then((result) => {
        this.state = result.state; // 更新页面状态
      }).catch((error) => {
        console.error("An error occurred:", error); // 如果 Promise 被拒绝，处理错误
      });
    },
    handleDelete(index) {
      console.log('删除页面id为：', index);
      const id = this.getDeviceInfo.id;
      const map = {
        "id": id,
        "method": "set_ble_page_state",
        "page": index,
        "state": 0
      }
      this.sendSerialData(map).then(() => {
        this.getPage(id);
        this.displayCount - 1;
        ElMessageBox.alert('页面删除成功！', '提示', {
          confirmButtonText: '确定',
          type: 'success',
        })
      });
    },
    handleClose() {
      this.showResponseModal = false;
      this.responseData = [];
    },
    async selectFunction(functionType) {
      if (functionType === 'LJ') {
        try {
          this.isSearching = true;
          const serialData = this.getDeviceInfo;
          const searchMap = {
            id: serialData.id,
            method: "search",
          };

          if (this.searchInterval) {
            clearInterval(this.searchInterval);
            console.log('清理之前的定时器');
          }
          this.searchInterval = setInterval(async () => {
            const res = await this.sendSerialData(searchMap);
            console.log('发送请求:', searchMap);
            if (res) {
              console.log('接收到结果，停止请求');
              clearInterval(this.searchInterval); // 停止定时器
              this.searchInterval = null; // 清空定时器
              this.waitForResponse(searchMap.id);
            } else {
              console.log('没有接收到结果，继续请求...');
            }
          }, 3000); // 每隔3秒发送一次请求


          // const res=await this.sendSerialData(searchMap);
          // this.waitForResponse(searchMap.id);


          // await this.sendSerialData(searchMap);

        // // 启动定时器，每3秒检查一次 displayedImages 是否为空
        // this.searchInterval = setInterval(async () => {
        //   if (this.displayedImages.length === 0) {
        //     console.log("数组为空，发送请求...");
        //     await this.sendSerialData(searchMap);
        //   } else {
        //     console.log("数组有数据，停止发送请求");
        //     clearInterval(this.searchInterval);  // 停止定时器
        //     this.searchInterval = null;  // 重置定时器ID
        //     // this.waitForResponse(searchMap.id);
        //   }
        // }, 3000);  // 3秒




        } catch (error) {
          ElMessageBox.alert('连接或发送数据失败！请检查设备通信！', {
            confirmButtonText: '确定',
            type: 'error',
          });
          this.isSearching = false;
          this.$router.push('/');
        }
        this.isTJSelected = false;
      } else if (functionType === 'UP') {

        try {
          this.status = "准备升级";
          this.showProgress = true;

          //准备从服务器或者什么地方获取 https://zzx.eacase.com.cn/firmware_v0.1.4.bin
          //当前为本地
          // const response = await fetch('/firmware_v0.1.5.bin');
          //测试服务器或者虚拟机获取（当前获取公司宝塔）
          // const response = await fetch('/firmware_v0.1.4.bin');
          const response = await fetch(' https://zx.eacase.com.cn/firmware_v.bin'); 
          if (!response.ok) {
            alert("w文件下载失败，请联系工作人员！");
            throw new Error('Failed to download update package');
          }
          this.fileData = new Uint8Array(await response.arrayBuffer());
          console.log("文件准备完毕！开始下载", this.fileData);
          const serializedData = JSON.parse(Cookies.get('deviceInfo') || '{}');
          console.log("串口信息", serializedData);
          if (!serializedData || !serializedData.id) {
            ElMessageBox.alert('连接过期!', {
              confirmButtonText: '确定',
              type: 'error',
            });
            return;
          }
          if (this.serialPort) {
            const cont = await this.$confirm("设备已连接请点击关闭后重新升级?", '提示', {
              confirmButtonText: '关闭',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => true).catch(() => false);
            if (cont) {
              await this.disconnectSerial();
              await delay_ms(500);
              if (this.serialPort.writer) {
                console.log("writer关闭");
                await this.serialPort.writer.close(); // 确保 writer 关闭
                this.serialPort.writer.releaseLock(); // 释放 writer 锁
              }
              if (this.serialPort.reader) {
                console.log("reader关闭");
                await this.serialPort.reader.cancel(); // 确保 reader 停止读取
                this.serialPort.reader.releaseLock(); // 释放 reader 锁
              }
              //等待500毫秒
              await delay_ms(500);
              //全部应该关闭了-进入传输
              await this.startYmodemTransfer(serializedData);
            }
          } else {
            await this.startYmodemTransfer(serializedData);
          }

        } catch (error) {
          console.log(error);
          ElMessageBox.alert('因安全策略,请先刷新当前页面，再固件升级！', {
            confirmButtonText: '确定',
            type: 'info',
          }).then(() => {
            this.$router.push('/upgradePage');
          });
        }
        this.isTJSelected = false;
      } else if (functionType === 'TJ') {
        const id = this.getDeviceInfo.id;

        let map = null;
        // 根据当前的页面状态（this.state）决定添加哪个页面
        if (this.state[0] === 0) {
          // 如果第二页未显示，添加第二页
          map = {
            "id": id,
            "method": "set_ble_page_state",
            "page": 2,
            "state": 1
          };
        } else if (this.state[1] === 0) {
          // 如果第三页未显示，添加第三页
          map = {
            "id": id,
            "method": "set_ble_page_state",
            "page": 3,
            "state": 1
          };
        } else {
          // 如果所有页面都已显示，不添加新页面
          ElMessageBox.alert('已到最大页面数！', {
            confirmButtonText: '确定',
            type: 'error',
          })
          return; // 直接返回，不继续执行
        }
        const res = this.sendSerialData(map);
        res.then((result) => {
          this.getPage(id);
          if (result.method == "set_ble_page_state_reply") {
            ElMessageBox.alert('添加页面成功', {
              confirmButtonText: '确定',
              type: 'success',
            })
          }
        }).catch(() => {
          ElMessageBox.alert('添加失败！', {
            confirmButtonText: '确定',
            type: 'error',
          }) // 如果 Promise 被拒绝，处理错误
        });

      }
    },
    // Ymodem开启串口
    async getSerData() {
      const port = await navigator.serial.requestPort({});
      await port.open({
        baudRate: 921600,
        dataBits: 8,
        stopBits: 1,
        parity: 'none',
        bufferSize: 1024,
        flowControl: 'none',
      });
      await delay_ms(500);
      this.serialPort = port;
      console.log("串口打开成功！", this.serialPort);
    },
    async writeSerial(data) {
      try {
        if (!this.serialPort) throw new Error('!serial');
        const writer = this.serialPort.writable.getWriter();
        const encoder = new TextEncoder();
        await writer.write(encoder.encode(data));
        writer.releaseLock();
        console.log("writeSerial success:", data);
      } catch (error) {
        console.log("writeSerial error:", error);
      }
    },
    async startYmodemTransfer(serializedData) {
      try {
        await this.getSerData();
        await delay_ms(1000);
        // const serializedData = JSON.parse(Cookies.get('deviceInfo'));
        // const serializedData = this.getDeviceInfo;
        const command = {
          id: serializedData.id,
          method: "update_controller",
          type: serializedData.type
        };
        await this.writeSerial(JSON.stringify(command));
        await delay_ms(500);
        //没有进度条和
        // const result = await transfer(this.serialPort, 'firmware_v0.1.5.bin', this.fileData, console.log);
        // console.log("Transfer result:", result);
        this.status = "正在升级";

        const connectButton = document.getElementById('connectButton');
        connectButton.style.backgroundColor = 'transparent';

        const checkConnectionButton = document.getElementById('checkConnectionButton');
        checkConnectionButton.style.backgroundColor = 'rgb(0, 245, 0)';

        const reader = this.serialPort.readable.getReader();
        const writer = this.serialPort.writable.getWriter();

        // 检查是否存在 writer 和 reader
        if (!reader) {
          this.log("reader不存在");
        }
        if (!writer) {
          this.log("writer不存在");
        }

        //加上进度条
        const result = await transfer({ reader, writer }, 'update.bin', this.fileData, console.log, (progress) => {
          this.progress = progress;
        });

        console.log("Transfer result:", result);
        if (result && result.totalBytes == result.writtenBytes) {
          this.$message.success('文件传输完毕！');

          ElMessageBox.alert('更新成功, 设备重启中，请返回首页重新连接！', '提示', {
            confirmButtonText: '确定',
            type: 'success',
          }).then(() => {
            console.log("更新成功");
            const checkConnectionButton = document.getElementById('checkConnectionButton');
            checkConnectionButton.style.backgroundColor = 'transparent';
            this.setConnectButtonStyle();
            this.isSearching = false;
            // this.$router.push('/');
            this.$router.push('/').then(() => {
              // 使用 location.reload() 强制刷新目标页面
              location.reload();
            }).catch(err => {
              console.error('跳转失败:', err);
            });
          });
        } else {
          this.$message.error('文件传输失败！');
        }
      } catch (error) {
        console.error("startYmodemTransfer error:", error);
        this.$message.error(`文件传输失败: ${error.message}`);
      }
    },
    async flash_ymodem(code, pre = '', after = '') {
      try {
        if (pre) await this.writeSerial(pre);
        await delay_ms(500);
        const encoder = new TextEncoder();
        const buffer = encoder.encode(code);
        const result = await transfer(this.serial, "code", buffer);
        this.MyLog("upload file result:", result);
        await delay_ms(500);
        if (after) await this.writeSerial(after);
      } catch (error) {
        this.MyLog("flash_ymodem error:", error);
      }
    },
    waitForResponse(requestId) {
      this.isSearching = false;
      const timeout = 3000; // 超时时间设置为 3 秒
      const startTime = Date.now();
      
    console.log("Request ID:", requestId);

    const checkResponse = () => {
      // 使用 filter 获取所有符合条件的响应
      const responses = this.getSerialData.filter(
        (data) => data.repeater && data.repeater.class === '001_Desk' && data.method === 'search_reply'
        // (data) => data.repeater.class === '001_Desk' && data.method === 'search_reply'
      );

    console.log("responses:", responses); // 打印找到的所有符合条件的响应

    if (responses.length > 0) {
      // 使用 reduce 创建一个唯一的设备列表，以 repeater.id 为键
      const uniqueResponses = responses.reduce((acc, current) => {
        const repeaterId = current.repeater.id;
        
        // 如果还没有这个 repeater.id，或者你有更新的逻辑（比如更晚的时间戳），则更新数据
        if (!acc[repeaterId]) {
          acc[repeaterId] = current; // 添加新的设备
        } else {
          // 如果 repeater.id 已经存在，可以根据你的需求决定是否替换旧的数据
          // 例如，可以检查版本号或时间戳来决定哪个数据是新的：
          // if (acc[repeaterId].timestamp < current.timestamp) {
          //   acc[repeaterId] = current;
          // }
          acc[repeaterId] = current; // 默认替换旧数据为最新的
        }

        return acc;
      }, {});

      // 将对象转换为数组，得到唯一的设备响应
      const uniqueResponsesArray = Object.values(uniqueResponses);
      // 遍历每一个唯一的响应并处理
      uniqueResponsesArray.forEach(response => {
        this.processResponseData(response);
      });
      clearTimeout(timer); // 找到响应后清除定时器
    } else if (Date.now() - startTime < timeout) {
      // 如果未超时，继续轮询
      timer = setTimeout(checkResponse, 100);
   
    } else {
      // 超时后处理
      console.error('响应超时');
    }
  };

  // 开始轮询
  let timer = setTimeout(checkResponse, 100);
},




    // waitForResponse(requestId) {
    //   this.isSearching = false;
    //   const timeout = 3000; // 超时时间设置为 3 秒
    //   const startTime = Date.now();
    //   console.log(requestId);
    //   const checkResponse = () => {
    //     // 使用 filter 获取所有匹配的响应
    //     const responses = this.getSerialData.filter(
    //       (data) => data.repeater.class === '001_Desk' && data.method === 'search_reply'
    //     );

    //     console.log("responses:", responses); // 打印出找到的所有响应

    //     // 判断是否有匹配的响应
    //     if (responses.length > 0) {
    //       // 遍历每一个响应并处理
    //       responses.forEach(response => {
    //         this.processResponseData(response);
    //       });
    //       clearTimeout(timer); // 找到响应后清除定时器
    //     } else if (Date.now() - startTime < timeout) {
    //       // 如果未超时，继续轮询
    //       timer = setTimeout(checkResponse, 100);
    //     } else {
    //       // 超时后处理
    //       console.error('响应超时');
    //     }
    //   };

    //   // 开始轮询
    //   let timer = setTimeout(checkResponse, 100);
    // },

    // waitForResponse(requestId) {
    //   this.isSearching = false;
    //   const timeout = 3000;
    //   const startTime = Date.now();
    //   const checkResponse = () => {
    //     const response = this.getSerialData.filter(
    //       (data) => data.id === requestId && data.method === 'search_reply'
    //     );
    //     console.log("response:", response);
    //     if (response) {
    //       this.processResponseData(response);
    //       clearTimeout(timer);
    //     } else if (Date.now() - startTime < timeout) {
    //       timer = setTimeout(checkResponse, 100);
    //     } else {
    //       console.error('响应超时');
    //     }
    //   };
    //   let timer = setTimeout(checkResponse, 100);
    // },
    // async handleConfirm(option) {
    //   console.log('用户选择了:', option);
    //   console.log(option.value);
    //   const modData = option.value;
    //   // const serialData = this.getDeviceInfo();
    //   const bindData = {
    //     id: modData.id,
    //     method: "bind",
    //     repeater_id: modData.repeater.id,
    //     class: modData.repeater.class
    //   };
    //   const storedOption = JSON.parse(localStorage.getItem("bind"));
    //   if(storedOption){
    //     // const TomeName= getOption.repeater.type;
    //     console.log("map",storedOption);
    //     ElMessageBox.alert(`当前已绑定设备:[ ${storedOption.displayText}],是否解绑，确定解绑后自动绑定所选择的设备`, {
    //         confirmButtonText: '确定',
    //         type: 'success',
    //       }).then(() => {
    //         // 这里执行用户点击确定后的操作
    //         console.log('用户点击了确定');
    //         const data={
    //           id: storedOption.value.id,
    //           "method":"unbind",
    //           "class":storedOption.value.repeater.class,
    //           "type":storedOption.value.repeater.type,
    //           "repeater_id":storedOption.value.repeater.id
    //         }
    //         this.sendSerialData(data)  
    //         localStorage.removeItem("bind");      
    //       }); 
    //   }   

    //   try {
    //     const response = await this.sendSerialData(bindData);
    //     if (response.method == 'bind_reply') {
    //       const optionData = JSON.parse(JSON.stringify(option));
    //       localStorage.setItem("bind", JSON.stringify(optionData));
    //       ElMessageBox.alert('绑定成功！', {
    //         confirmButtonText: '确定',
    //         type: 'success',
    //       });

    //     } else {
    //       ElMessageBox.alert('失败', {
    //         confirmButtonText: '确定',
    //         type: 'error',
    //       });

    //     }
    //   } catch (error) {
    //     console.error('绑定失败:', error);
    //     ElMessageBox.alert('失败', {
    //       confirmButtonText: '确定',
    //       type: 'error',
    //     });
    //   }

    //   this.showSelectModal = false;
    // },
    async handleConfirm(option) {
      if(!option){
        ElMessageBox.alert('请先选择需要绑定的设备！', {
            confirmButtonText: '确定',
            type: 'error',
          });
          return;
        }
        console.log('用户选择了:', option);
        const modData = option.value;

        // 准备绑定新设备的数据
        const bindData = {
          id: modData.id,
          method: "bind",
          repeater_id: modData.repeater.id,
          class: modData.repeater.class
        };

        // 检查本地是否有已绑定的设备
        const storedOption = JSON.parse(localStorage.getItem("bind"));

        // 如果本地没有已绑定设备，直接执行绑定操作
        if (!storedOption) {
          await this.bindNewDevice(bindData, option);
          return;
        }
        // 如果本地有已绑定设备，弹出解绑确认
        console.log("当前已绑定设备", storedOption);

        ElMessageBox.confirm(
          `当前已绑定设备:[ ${storedOption.displayText} ], 是否解绑并绑定新设备？`,
          '确认解绑',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }
        ).then(async () => {
          // 如果用户点击确定，执行解绑操作
          console.log('用户确认解绑');
          const unbindData = {
            id: storedOption.value.id,
            method: "unbind",
            class: storedOption.value.repeater.class,
            type: storedOption.value.repeater.type,
            repeater_id: storedOption.value.repeater.id
          };

          try {
            this.sendSerialData(unbindData); // 发送解绑请求
            localStorage.removeItem("bind"); // 移除已绑定设备
            // 等待一段时间，确保解绑成功
            await delay_ms(1000);
            // 解绑成功后，继续绑定新设备
            // console.log('解绑成功，开始绑定新设备');
            // await this.bindNewDevice(bindData, option);
          } catch (error) {
            console.error('解绑失败:', error);
            ElMessageBox.alert('解绑失败', {
              confirmButtonText: '确定',
              type: 'error',
            });
          }finally {
            //只要发送成功，不管返回的消息都要执行下一步
            localStorage.removeItem("bind"); // 移除已绑定设备
            // 解绑成功后，继续绑定新设备
            console.log('解绑成功，开始绑定新设备');
            this.bindNewDevice(bindData, option);
          }
        }).catch(() => {
          // 用户取消解绑
          console.log('用户取消了解绑');
        });
      },

      // 封装绑定新设备的逻辑 (有时设备返回的流是断的实际已经绑定解绑成功，直接不等返回直接绑定（先存储后发送吧）)
      async bindNewDevice(bindData, option) {
        try {
          // const response = await this.sendSerialData(bindData);
          // if (response.method === 'bind_reply') {
            const optionData = JSON.parse(JSON.stringify(option));
            localStorage.setItem("bind", JSON.stringify(optionData)); // 存储新绑定设备
            ElMessageBox.alert('绑定成功！', {
              confirmButtonText: '确定',
              type: 'success',
            });
            await this.sendSerialData(bindData);
          // } else {
          //   throw new Error('绑定失败');
          // }
        } catch (error) {
          console.error('绑定失败:', error);
          ElMessageBox.alert('绑定失败', {
            confirmButtonText: '确定',
            type: 'error',
          });
        }
      },

    processResponseData(response) {
      console.log('类型:', response.repeater.class);
      let newData = [];
      
      switch (response.repeater.class) {
        case '001_Desk':
          newData = [{
            displayText: '升降桌中继器：' + response.repeater.type,
            value: response,
          }];
          break;
        case 'type2':
          newData = [{
            displayText: 'Field A',
            value: response.data.fieldA,
          },
          {
            displayText: 'Field B',
            value: response.data.fieldB,
          }];
          break;
        default:
          newData = [{
            displayText: 'Default Field',
            value: response.data.defaultField,
          }];
          break;
      }

      // 累加数据到 responseData 数组
      this.responseData = [...this.responseData, ...newData];

      this.isSearching = false;
      this.showResponseModal = true;
    },
    closeModal() {
      this.isSearching = false;
      this.$emit('close');
    },

    loadContent(imageIddex) {
      // this.$router.push('/settingsPage');
      this.$router.push({ name: 'settingsPage', params: { imageId: imageIddex } });
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      } else {
        this.currentImageIndex = this.displayedImages.length - 1;
      }
    },
    nextImage() {
      if (this.currentImageIndex < this.displayedImages.length - 1) {
        this.currentImageIndex++;
      } else {
        this.currentImageIndex = 0;
      }
    },
    // loadContent(image) {
    //   console.log(`${image.id} clicked`);
    //   // 例如：跳转到图像的详细页面

    //   window.location.href = `url/to/${image.id}`;
    // }

  },
};
</script>

<style>
.container {
  display: flex;
  min-height: 100vh;
  width: 100%;
}

.white-content {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: -30px;
  align-items: center;
}

.gray-bg {
  width: 80%;
  flex: 5;
  background-color: #f4f4f4;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

.header1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column;
  padding-right: 5px;
}

.nav-items1 {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 12px;
  margin-right: 42px
}

.gray-divider {
  color: gray;
  font-weight: bold;
  margin: 0 5px;
}

.function-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  padding-top: 150px;
  padding-left: 24px;
  width: 114px;
}

.function-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 18px;
  transition: background-color 0.3s;
}

.function-item:hover {
  background-color: #f8f9fa;
}

.function-item p {
  margin-left: 5px;
}

.TJ i:before {
  content: "\f067";
}

.LJ i:before {
  content: "\f1e6";
}

.UP i:before {
  content: "\f093";
}

.icon-line {
  padding-top: 50px;
  padding-left: 25px;
}

.img-div {
  width: 420px;
  /* height: auto; */
  margin-right: 350px;
  margin-top: 280px;
  cursor: pointer;
  /* 添加手型指针 */
  transition: transform 0.3s;
  /* 添加过渡效果 */
}

.showProgress {
  display: flex;
  align-items: center;
}

.progress-bar-container {
  flex-grow: 1;
  height: 20px;
  background-color: #f3f3f3;
  border-radius: 10px;
  overflow: hidden;
  margin-right: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.5s;
}


.swiperbox {
  /* width: 30%; */
  height: 100%;
  /* 设置一个合适的高度，例如 300px */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* 居中对齐内容 */
}

.mySwiper {
  width: 60%;
  height: auto;
  /* 确保 Swiper 组件充满容器 */
}

.swiper-slide {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  /* 根据需要调整字体大小 */
  /* background-color: #f0f0f0; */
  /* 设置一个背景色以便更容易看到 */
  height: 100%;
  /* 确保每个 slide 充满 Swiper 容器 */
}

.text-img {
  margin-left: 300px;
  font-size: 20 Georgia, serif;
  background-color: #818181e3;
  color: #fff;
  border-radius: 10px;
  /* 圆角 */
  padding: 5px;
  font-family: Georgia, serif;
  font-weight: bold;
}


/* 删除按钮样式 */
.delete-button {
  position: absolute;
  bottom: 28px;
  /* 调整这个值根据需求 */
  left: 0px;
  /* 左对齐 */
  background-color: red;
  color: white;
  border: none;
  padding: 15px 15px;
  /* 确保有足够的内边距 */
  border-radius: 10px;
  cursor: pointer;
  /* z-index: 10;   确保按钮位于其他元素之上 */
}

.delete-button:hover {
  background-color: darkred;
}


/**弹窗的样式 */
.modal {
  position: fixed;
  /* 确保弹窗固定在视窗上，不受父元素影响 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  /* 半透明背景 */
  z-index: 9999;
  /* 设定高的 z-index，确保在页面最上层 */
}

.modal-content {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  /* 添加圆角 */
  width: 80%;
  max-width: 600px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  /* 添加阴影 */
  z-index: 10000;
  /* 确保弹窗内容在最高层 */
  animation: fadeIn 0.3s ease-out;
  /* 添加弹出动画 */
}

.modal-content p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

/* 优化按钮容器的布局 */
.modal-content .button-container {
  display: flex;
  justify-content: flex-end;
  /* 按钮靠右排列 */
  gap: 15px;
  /* 设置按钮之间的间距 */
  margin-top: 20px;
  /* 与内容保持距离 */
}

/* 优化按钮样式，确保有足够的外边距 */
.modal-content button {
  background-color: #4CAF50;
  /* 按钮背景色 */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  /* 按钮圆角 */
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  /* 确保按钮之间有外边距 */
  transition: background-color 0.3s ease;
  /* 过渡效果 */
}

.modal-content button:first-child {
  margin-left: 0;
  /* 第一个按钮不需要左边距 */
}

.modal-content button:hover {
  background-color: #45a049;
  /* 按钮悬停效果 */
}

/* 关闭按钮样式 */
.modal-content .close-button {
  background-color: #f44336;
  /* 关闭按钮颜色 */
}

.modal-content .close-button:hover {
  background-color: #d32f2f;
}
</style>
